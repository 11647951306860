import { isNil } from "lodash-es";
import type { JobWithClient } from "types";

export function error404(message: string) {
  return createError({
    statusCode: 404,
    message: message || "Not found",
    fatal: true,
  });
}

export function maybeArray<T>(value: T | T[]): T[] {
  if (isNil(value) || value === "") return [];
  return Array.isArray(value) ? value : [value];
}

export function stateCode(stateNameOrCode: string) {
  stateNameOrCode = stateNameOrCode.trim();
  const { states } = useAppConfig();
  const st = states.find((s) => s[0] === stateNameOrCode.toUpperCase());
  if (st) return st[0];

  const st2 = states.find(
    (s) => s[1].toLowerCase() === stateNameOrCode.toLowerCase()
  );
  if (st2) return st2[0];
  return undefined;
}

export function getAllStateCodes() {
  const { states } = useAppConfig();
  return states.map((s) => s[0]);
}

export function getAllStateNames() {
  const { states } = useAppConfig();
  return states.map((s) => s[1]);
}

export function isZip(string: string) {
  return /^\d{5}$/.test(string);
}

export function isStateNameOrStateCode(string: string) {
  if (!string) return false;
  // it's a state code (abbreviation)
  if (getAllStateCodes().includes(string.toUpperCase())) {
    return true;
  }

  // it's a state name (spelled out)
  if (
    getAllStateNames()
      .map((state) => state.toLowerCase())
      .includes(string.toLowerCase())
  ) {
    return true;
  }

  // it
  return false;
}

export function jobObjectToRouteRecord(job: JobWithClient) {
  const isCustomDomain = useIsCustomDomain();
  const unbranded = useUnbranded();

  if (unbranded.value || isCustomDomain.value) {
    return { path: `/u/jobs/${job.client.slug}/${job.slug}` };
  }

  return { path: `/jobs/${job.client.slug}/${job.slug}` };
}

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export function getFormSourceData() {
  return {
    source: window.location.href,
    sourceUrl: window.location.origin + window.location.pathname,
    sourceId: useUnbranded().value ? 6 : 5,
    utmSource: useRoute().query.utm_source,
    utmCampaign: useRoute().query.utm_campaign,
    utmMedium: useRoute().query.utm_medium,
  };
}

export function oklchIsLight(color: string) {
  const normalized = color.replace(/^oklch\(/, "").replace(/\)$/, "");
  const lightness = normalized.split(" ").at(0)?.replace("%", "").trim();
  return lightness ? parseInt(lightness) > 70 : false;
}

export function isOklchColor(color: string) {
  if (!color) return false;
  if (color.startsWith("oklch(")) return true;
  if (color.includes("%") && color.split(" ").length >= 3) return true;
  return false;
}

type NestedObject = {
  [key: string]: NestedObject | any;
};

export function replacePlaceholders(
  template: string,
  data: NestedObject
): string {
  return template.replace(
    /\[([^\]]+)\]/g,
    (match: string, path: string): string => {
      const keys: string[] = path.trim().split(".");
      let value: any = data;

      for (const key of keys) {
        if (value && typeof value === "object" && key in value) {
          value = value[key];
        } else {
          return match; // Return original placeholder if path is invalid
        }
      }

      return value !== undefined && value !== null ? String(value) : match;
    }
  );
}

export function deepReplacePlaceholders(
  obj: NestedObject,
  data: NestedObject
): NestedObject {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => deepReplacePlaceholders(item, data));
  }

  const result: NestedObject = {};
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === "string") {
      result[key] = replacePlaceholders(value, data);
    } else if (typeof value === "object" && value !== null) {
      result[key] = deepReplacePlaceholders(value, data);
    } else {
      result[key] = value;
    }
  }
  return result;
}
